<template>
  <b-container>
    <h1>{{$t('createUser.newUser')}}</h1>

    <b-card class="mt-2 mr-md-2 shadow flex-fill">
      <h2 class="card-title">
        <i class="fas fa-user-edit mr-2"></i>{{ $t("user.userData") }}
      </h2>
      <b-form @submit.prevent="createUser">
        <b-form-group
          :label="$t('user.username')"
          label-for="newUser"
          :description="$t('newUser.usernameCantBeChanged')"
          
        >
          <b-form-input id="newUser" v-model="user.username" :state="usernameDuplicate" autocomplete="off" :disabled="autofillBlocker"></b-form-input>
          <b-form-invalid-feedback :state="usernameDuplicate">
            <i class="fas fa-exclamation-circle mr-2"></i
            >{{ $t("createUser.invalidUsername", {username: this.user.username}) }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group :label="$t('user.firstName')" label-for="firstName">
          <b-form-input
            id="firstName"
            v-model="user.firstName"
            type="text"
            autocomplete="off"
            :disabled="autofillBlocker"
          ></b-form-input>
        </b-form-group>

        <b-form-group :label="$t('user.lastName')" label-for="lastName">
          <b-form-input
            id="lastName"
            v-model="user.lastName"
            type="text"
            autocomplete="off"
            :disabled="autofillBlocker"
          ></b-form-input>
        </b-form-group>


        <b-form-group :label="$t('createUser.password')" label-for="password">
          <b-form-input
            id="password"
            v-model="user.password1"
            type="password"
            autocomplete="off"
            :disabled="autofillBlocker"
          ></b-form-input>
        </b-form-group>

        <b-form-group :label="$t('createUser.passwordVerify')" label-for="password2">
          <b-form-input
            id="password2"
            v-model="user.password2"
            type="password"
            autocomplete="off"
            :state="verifyPasswords"
            :disabled="autofillBlocker"
          ></b-form-input>
          <b-form-invalid-feedback :state="verifyPasswords">
            <i class="fas fa-exclamation-circle mr-2"></i
            >{{ $t("createUser.passwordsNotMatching") }}
          </b-form-invalid-feedback>
        </b-form-group>


        <div class="d-flex justify-content-end">
          <b-button type="submit" variant="primary"
            ><i class="fas fa-user-plus mr-2"></i>{{$t('createUser.CreateUser')}}</b-button
          >
        </div>
      </b-form>
    </b-card>
  </b-container>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
  name: "CreateUser",
  data() {
    return {
      user: {
        username: "",
        password1: "",
        password2: "",
        firstName: "",
        lastName: "",
      },
      usernames: [],
      autofillBlocker: true,
    };
  },
  computed: {
    usernameDuplicate(){
      if(this.user.username == '') return null;
      return !this.usernames.includes(this.user.username);
    },
    verifyPasswords() {
      if(this.user.password1 == '' || this.user.password2 == '') return null;
      return this.user.password1 === this.user.password2;
    }
  },
  methods: {
    ...mapActions('alerts', ['fireAlert']),
    loadUsers() {
      //loads a list of all existing users to check the entered username for duplicates
      axios
        .get(`/my-devices/api/users`)
        .then((res) => {
          this.usernames = res.data.map((name) => name['name']);
        })
        .catch((err) => {
          console.error(err);
          this.fireAlert([this.$t('Error'), this.$t('createUser.errorLoadingUsernames'), 'danger']);
        })
    },
    createUser() {
      axios
        .post(`/my-devices/api/users`,JSON.stringify({
          name: this.user.username.trim(),
          password: this.user.password1,
          attributes: [
            {
              name: 'firstName',
              value: this.user.firstName.trim()
            },
            {
              name: 'lastName',
              value: this.user.lastName.trim()
            },
            {
              name: 'created',
              value: new Date().toISOString()
            }
          ]
        }))
        .then(() => {
          this.fireAlert([this.$t('Success'), this.$t('createUser.successfullyCreated'), 'success']);
          this.$router.push({name: 'User', params: {username: this.user.username}});
        })
        .catch((err) => {
          this.fireAlert([this.$t('Error'), this.$t('createUser.ErrorCreating'), 'danger']);
          console.error(err);
        })
    },
  },
  mounted() {
    this.loadUsers();
    // this removes the disabled property from all input fields after 810 ms which forces the Browser not to prefill any stored form-data. (810 ms) are the time limit which work. All times < 810 ms doesn't seem to work in Chrome.  
    setTimeout(() => {this.autofillBlocker = false}, 810);
  }
};
</script>

<style>
</style>